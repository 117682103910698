<template>
  <div class="editions">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <!-- <EditionSelect
            @select="select"
            :edition="editionObj"
            :allowClear="false"
            class="mb-3"
          /> -->

          <b-form-group
            id="dees_map"
            label="Dees Map"
            label-for="dees_map_f"
          >
            <b-form-input
              id="dees_map_f"
              v-model="dees_map"
              placeholder="Dees Map"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="dees_group"
            label="Dees Group"
            label-for="dees_group_f"
          >
            <b-form-input
              id="dees_group_f"
              v-model="dees_group"
              placeholder="Dees Group"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="dees_type"
            label="Dees Type"
            label-for="dees_type_f"
          >
            <b-form-select
              id="dees_type_f"
              v-model="dees_type"
              :options="options"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HelpStore from "@/store/helpstore.js";

export default {
  name: "HCCDeesMapsAutocomplete",
  props: [],
  data() {
    return {
      dees_map: this.dees_map,
      dees_group: this.dees_group,
      dees_type: this.dees_type,
      options: [
        { value: "map", text: "map" },
        { value: "supplement", text: "supplement" }
      ]
    };
  },
  methods: {
  },
  updated() {
    HelpStore.item = {
      dees_map: this.dees_map,
      dees_group: this.dees_group,
      dees_type: {"name": this.dees_type},
    }
  },
};
</script>
