<template>
  <div class="details">
    <div v-if="loading || !hcc">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
          <b-col class="mt-5">
            <div class="spinner-border m-5" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle
              v-bind:title="hcc.description"
              :pretitle="'HCC details'"
              :safe="true"
            />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode">
                <button class="btn-legam-xs mb-2 btn-secondary" v-b-modal.modal-duplicate-hcc>
                  <b-icon icon="plus-circle-fill"></b-icon>
                  Duplicate HCC
                </button>
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary" :disabled="checkAlreadyExists">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button
                  v-if="$store.getters.checkRole('legam.scripta.head.delete')"
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <!-- <button
                  @click="showHCCs"
                  class="btn-legam-xs mb-2"
                >
                  <b-icon icon="list"></b-icon>
                  Show HCCs
                </button> -->
                <button
                  @click="editMode = true"
                  v-if="$store.getters.checkRole('legam.scripta.head.modify')"
                  class="btn-legam-xs mb-2"
                >
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button
                  v-if="
                    !$store.getters.checkRole('legam.scripta.head.modify') &&
                    $store.getters.checkRole('legam.scripta.head.delete')
                  "
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Code</h6>
                  <span v-html="this.hccCode"></span>
                  <div v-if="checkAlreadyExists" style="color: red" class="mt-2">HCC configuration already exists</div>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <h6>Head</h6>
                  <HeadAutocomplete
                    @select="hcc.head = $event; checkHCC()"
                    :head="hcc.head"
                    v-if="editMode"
                  />
                  <span v-else v-html="hcc.head.form"></span>
                </b-col>
                <b-col>
                  <h6>Description</h6>
                  <b-form-input
                    id="description"
                    v-model="hcc.description"
                    placeholder="Description"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="hcc.description"></span>
                </b-col>
                <b-col>
                  <h6>Comment</h6>
                  <b-form-input
                    id="comment"
                    v-model="hcc.comment"
                    placeholder="Comment"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="hcc.comment"></span>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <h6>Phonol Env</h6>
                  <PhonolEnvAutocomplete
                    @select="hcc.phonolEnv = $event; checkHCC()"
                    :phonolEnv="hcc.phonolEnv"
                    :headId="hcc.headId"
                    v-if="editMode"
                  />
                  <span v-else v-html="hcc.phonolEnv.form"></span>
                </b-col>
                <b-col>
                  <h6>Base Etym</h6>
                  <BaseEtymAutocomplete
                    @select="hcc.baseEtym = $event; checkHCC()"
                    :baseEtym="hcc.baseEtym"
                    :headId="hcc.headId"
                    v-if="editMode"
                  />
                  <span v-else v-html="hcc.baseEtym.form"></span>
                </b-col>
                <b-col>
                  <h6>Base Etym Var</h6>
                  <BaseEtymVarAutocomplete
                    @select="hcc.baseEtymVar = $event; checkHCC()"
                    :baseEtymVar="hcc.baseEtymVar"
                    :headId="hcc.headId"
                    v-if="editMode"
                  />
                  <span v-else v-html="hcc.baseEtymVar.form"></span>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <h6>Base Indiv</h6>
                  <BaseIndivAutocomplete
                    @select="hcc.baseIndiv = $event; checkHCC()"
                    :baseIndiv="hcc.baseIndiv"
                    :headId="hcc.headId"
                    v-if="editMode"
                  />
                  <span v-else v-html="hcc.baseIndiv.form"></span>
                </b-col>
                <b-col>
                  <h6>Base Special</h6>
                  <BaseSpecialAutocomplete
                    @select="hcc.baseSpecial = $event; checkHCC()"
                    :baseSpecial="hcc.baseSpecial"
                    :headId="hcc.headId"
                    v-if="editMode"
                  />
                  <span v-else v-html="hcc.baseSpecial.form"></span>
                </b-col>
                <b-col>
                  <h6>Evolution</h6>
                  <EvolutionAutocomplete
                    @select="hcc.evolution = $event; checkHCC()"
                    :evolution="hcc.evolution"
                    :headId="hcc.headId"
                    v-if="editMode"
                  />
                  <span v-else v-html="hcc.evolution.form"></span>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <h6>Issue Type</h6>
                  <IssueTypeAutocomplete
                    @select="hcc.issueType = $event; checkHCC()"
                    :issueType="hcc.issueType"
                    :headId="hcc.headId"
                    v-if="editMode"
                  />
                  <span v-else v-html="hcc.issueType.form"></span>
                </b-col>
                <b-col>
                  <h6>Issue Var</h6>
                  <IssueVarAutocomplete
                    @select="hcc.issueVar = $event; checkHCC()"
                    :issueVar="hcc.issueVar"
                    :headId="hcc.headId"
                    v-if="editMode"
                  />
                  <span v-else v-html="hcc.issueVar.form"></span>
                </b-col>
                <b-col>
                  <h6>Issue Phon</h6>
                  <IssuePhonAutocomplete
                    @select="hcc.issuePhon = $event; checkHCC()"
                    :issuePhon="hcc.issuePhon"
                    :headId="hcc.headId"
                    v-if="editMode"
                  />
                  <span v-else v-html="hcc.issuePhon.form"></span>
                </b-col>
              </b-row>
              <hr>
              <b-row class="mt-4">
                <b-col>
                  <h6>Examples Latin</h6>
                  <b-form-input
                    id="examplesLatin"
                    v-model="hcc.examplesLatin"
                    placeholder="Examples Latin"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="hcc.examplesLatin"></span>
                </b-col>
                <b-col>
                  <h6>Examples Vernacular</h6>
                  <b-form-input
                    id="examplesVernacular"
                    v-model="hcc.examplesVernacular"
                    placeholder="Examples Vernacular"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="hcc.examplesVernacular"></span>
                </b-col>
              </b-row>
              <hr>
            </article>
          </b-col>
        </b-row>


        <div class="accordion mt-4" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-1 class="text-left">
                Dees Maps <small>({{ hcc.dees_map.length }} {{ hcc.dees_map.length === 1 ? 'entry' : "entries" }})</small>
                <span class="when-open float-right">-</span>
                <span class="when-closed float-right">+</span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion-1" role="tabpanel">
              <b-card-body>
                <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-deesmap>
                  <b-icon icon="plus"></b-icon>
                  Add
                </span>
                <b-modal id="modal-add-deesmap" @ok="addDeesMap" title="Add Dees Map" ok-only ok-variant="secondary" size="lg">
                  <HCCDeesMapsAutocomplete />
                </b-modal>
                  <HCCDeesMapsList
                    :showTotal="false"
                    :dees_map="hcc.dees_map"
                    @delete="deleteDeesMap"
                    :editMode="editMode"
                    v-if="hcc.dees_map.length"
                  />
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>


        <b-row class="mt-4">
          <b-col>
            <h4>Occurrences</h4>
            <div class="spinner-border m-5" role="status" v-if="loadingOccurrences">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else class="mt-4">
              <DMSOccurrencesTable
                :context="hccOccurrences"
                :viewOnly="true"
                @update="updateOccurrences"
                :key="indexOccurrences"
                :hcc="hcc"
                v-if="hccOccurrences && hccOccurrences.results"
              />
              <span v-else>No data</span>
            </div>
          </b-col>
        </b-row>
        <!-- <div class="mt-4">
          <h6>Logs</h6>
          <ChangeLogsTable
            :objectType="'hcc'"
            :objectId="id"
            :key="'logs' + indexLog"
          />
        </div> -->
      </b-container>

    </div>
    <!-- <b-modal id="modal-create-hcc" title="Create HCC" ok-only ok-variant="secondary" @ok="createHCC" size="xl">
      <HCCCreate :head="head" ref="HCCCreateRef" />
    </b-modal> -->
    <b-modal
      id="modal-duplicate-hcc"
      title="Duplicate Head Category Config"
      ok-title="Save"
      @ok="saveHCC"
      size="lg"
    >
      <HCCCreate :hccFromId="id" ref="HCCCreateRef" />
    </b-modal>
  </div>
</template>

<style scoped>
* >>> th {
  font-size: 10px !important;
  text-transform: uppercase;
  color: #bbbbbb;
}
* >>> .container-xxl {
  padding-left: 0;
}

* >>> .container-xxl > .row .form-group > label {
  display: none !important;
}
</style>

<script>
import { mapState } from "vuex";
import router from "@/router";
import PageTitle from "@/components/PageTitle.vue";
// import ChangeLogsTable from "@/components/ChangeLogsTable.vue";
import DMSOccurrencesTable from "@/views/doc/DMSOccurrencesTable.vue";
import BaseEtymAutocomplete from "@/components/Scripta/BaseEtymAutocomplete.vue";
import BaseEtymVarAutocomplete from "@/components/Scripta/BaseEtymVarAutocomplete.vue";
import BaseIndivAutocomplete from "@/components/Scripta/BaseIndivAutocomplete.vue";
import BaseSpecialAutocomplete from "@/components/Scripta/BaseSpecialAutocomplete.vue";
import EvolutionAutocomplete from "@/components/Scripta/EvolutionAutocomplete.vue";
import IssuePhonAutocomplete from "@/components/Scripta/IssuePhonAutocomplete.vue";
import IssueTypeAutocomplete from "@/components/Scripta/IssueTypeAutocomplete.vue";
import IssueVarAutocomplete from "@/components/Scripta/IssueVarAutocomplete.vue";
import PhonolEnvAutocomplete from "@/components/Scripta/PhonolEnvAutocomplete.vue";
import HeadAutocomplete from "@/components/Scripta/HeadAutocomplete.vue";
import HCCCreate from "@/components/Scripta/HCCCreate.vue";
import HCCDeesMapsList from '@/components/Scripta/HHCDeesMapsList.vue'
import HCCDeesMapsAutocomplete from "@/components/Scripta/HCCDeesMapsAutocomplete.vue";
import HelpStore from '@/store/helpstore.js'

export default {
  name: "HCCDetails",
  data() {
    return {
      id: this.$route.params.id,
      editMode: false,
      loading: true,
      indexLog: 0,
      indexOccurrences: 0,
      loadingOccurrences: true,
      checkAlreadyExists: false,
    };
  },
  components: {
    PageTitle,
    // ChangeLogsTable,
    DMSOccurrencesTable,
    BaseEtymAutocomplete,
    BaseEtymVarAutocomplete,
    BaseIndivAutocomplete,
    BaseSpecialAutocomplete,
    EvolutionAutocomplete,
    HeadAutocomplete,
    IssuePhonAutocomplete,
    IssueTypeAutocomplete,
    IssueVarAutocomplete,
    PhonolEnvAutocomplete,
    HCCCreate,
    HCCDeesMapsList,
    HCCDeesMapsAutocomplete
  },
  computed: {
    ...mapState({
      hcc: (state) => state.hcc.hcc,
      hccOccurrences: (state) => state.hcc.hccOccurrences,
      hccCode: (state) => `${state.hcc.hcc.head.form}=${state.hcc.hcc.phonolEnv.form}.${state.hcc.hcc.baseEtym.form}-${state.hcc.hcc.baseEtymVar.form}-${state.hcc.hcc.baseIndiv.form}-${state.hcc.hcc.baseSpecial.form}.${state.hcc.hcc.evolution.form}.${state.hcc.hcc.issueType.form}-${state.hcc.hcc.issuePhon.form}`
    }),
  },
  mounted() {
    this.$store.state.docediting.selectedTokensLemmas = []
    this.$store.dispatch("loadHCC", this.id);
    this.$store.dispatch("loadOccurrences", this.id);
  },
  watch: {
    id() {
      this.loading = true;
      this.$store.dispatch("loadHCC", this.id);
    },
    hcc() {
      this.loading = false;
    },
    hccOccurrences() {
      this.loadingOccurrences = false
      this.indexOccurrences++
    },
  },
  methods: {
    checkHCC() {
      if (
        this.hcc.phonolEnvId == this.hcc.phonolEnv.id &&
        this.hcc.baseEtymId == this.hcc.baseEtym.id &&
        this.hcc.baseEtymVarId == this.hcc.baseEtymVar.id &&
        this.hcc.baseIndivId == this.hcc.baseIndiv.id &&
        this.hcc.baseSpecialId == this.hcc.baseSpecial.id &&
        this.hcc.evolutionId == this.hcc.evolution.id &&
        this.hcc.issuePhonId == this.hcc.issuePhon.id &&
        this.hcc.issueTypeId == this.hcc.issueType.id &&
        this.hcc.issueVarId == this.hcc.issueVar.id
      ){
        this.checkAlreadyExists = false
      }
      else {
        this.$store.dispatch("checkHCCExists", this.hcc).then((data) => {
          this.checkAlreadyExists = data.data.result
        });
      }
    },
    // showHCCs() {
    //   router.push(`/hccs/${this.id}`);
    // },
    // createHCC(bvModalEvt) {
    //   bvModalEvt.preventDefault()
    //   if (this.$refs.HCCCreateRef.validate()){
    //     this.$store.dispatch("saveHCC", HelpStore.item).then(() => {
    //       this.$bvModal.hide("modal-create-hcc")
    //       this.$store.dispatch("addNotification", {
    //         type: "success",
    //         text: `HCCs created`,
    //       });
    //     });
    //   }
    //   else {
    //     this.$store.dispatch("addNotification", {
    //       type: "warning",
    //       text: `Enter all required data`,
    //     });
    //   }
    // },
    updateOccurrences() {
      this.$store.dispatch("loadOccurrences", this.id);
    },
    saveHCC(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.$refs.HCCCreateRef.validate()){
        this.$store.dispatch("saveHCC", HelpStore.item).then((data) => {
          if (data.data.hccId != this.id) {
            router.push(`/hcc/${data.data.hccId}`)
            router.go()
            // this.id = data.data.hccId
          }
          this.$bvModal.hide("modal-duplicate-hcc")
          this.$store.dispatch("addNotification", {
            type: "success",
            text: `HCCs created`,
          });
        });
      }
      else {
        this.$store.dispatch("addNotification", {
          type: "warning",
          text: `Please, enter all required data and check if HCC is not exists already`,
        });
      }
    },
    cancelEdit() {
      this.editMode = false;
      this.$store.dispatch("loadHCC", this.id);
      this.$store.dispatch("addNotification", {
        type: "warning",
        text: `Changes dismissed`,
      });
    },
    saveEdit() {
      this.editMode = false;
      this.$store.dispatch("saveHCC", this.hcc).then(() => {
        this.$store.dispatch("addNotification", {
          type: "success",
          text: `Changes saved`,
        });
        this.indexLog++;
      });
      this.$store.dispatch("loadHCC", this.id);
    },
    deleteItem() {
      let msg = "Are you sure?"
      if (this.hccOccurrences && this.hccOccurrences.results){
        msg = "This HCC is not empty. Do you really want to delete it?"
      }
      this.$bvModal.msgBoxConfirm(msg).then((value) => {
        if (value) {
          this.$store.dispatch("deleteHCC", this.id).then(() => {
            router.push(`/head/${this.hcc.headId}`);
          });
        }
      });
    },
    addDeesMap(){
      this.$bvModal.hide('modal-add-deesmap')
      this.$store.dispatch('addNotification', {
        type: 'success',
        text: `Dees Map added`
      })
      this.hcc.dees_map.push({...HelpStore.item, hcc_id: this.id})
    },
    deleteDeesMap(dees_mapId){
      this.hcc.dees_map = this.hcc.dees_map.filter(dees_map => dees_map.id != dees_mapId)
    },

    // addBaseEtym(){
    //   this.modalBaseEtym = null;
    //   if (HelpStore.item){
    //     this.$bvModal.hide('modal-add-baseetym')

    //     if (HelpStore.item._index != null){
    //       this.head.baseEtyms[HelpStore.item._index] = HelpStore.item;
    //     }
    //     else {
    //       this.head.baseEtyms.push(HelpStore.item);
    //     }
    //     this.indexBaseEtym++;
    //   }
    // },
    // editBaseEtym(baseEtym){
    //   this.modalBaseEtym = baseEtym;
    //   this.$bvModal.show('modal-add-baseetym');
    // },
    // deleteBaseEtym(baseEtymId){
    //   this.head.baseEtyms = this.head.baseEtyms.filter(baseEtym => baseEtym.id != baseEtymId)
    // },

    // addBaseEtymVar(){
    //   this.modalBaseEtymVar = null;
    //   if (HelpStore.item){
    //     this.$bvModal.hide('modal-add-baseetymvar')

    //     if (HelpStore.item._index != null){
    //       this.head.baseEtymVars[HelpStore.item._index] = HelpStore.item;
    //     }
    //     else {
    //       this.head.baseEtymVars.push(HelpStore.item);
    //     }
    //     this.indexBaseEtymVar++;
    //   }
    // },
    // editBaseEtymVar(baseEtymVar){
    //   this.modalBaseEtymVar = baseEtymVar;
    //   this.$bvModal.show('modal-add-baseetymvar');
    // },
    // deleteBaseEtymVar(baseEtymVarId){
    //   this.head.baseEtymVars = this.head.baseEtymVars.filter(baseEtymVar => baseEtymVar.id != baseEtymVarId)
    // },

    // addBaseIndiv(){
    //   this.modalBaseIndiv = null;
    //   if (HelpStore.item){
    //     this.$bvModal.hide('modal-add-baseindiv')

    //     if (HelpStore.item._index != null){
    //       this.head.baseIndivs[HelpStore.item._index] = HelpStore.item;
    //     }
    //     else {
    //       this.head.baseIndivs.push(HelpStore.item);
    //     }
    //     this.indexBaseIndiv++;
    //   }
    // },
    // editBaseIndiv(baseIndiv){
    //   this.modalBaseIndiv = baseIndiv;
    //   this.$bvModal.show('modal-add-baseindiv');
    // },
    // deleteBaseIndiv(baseIndivId){
    //   this.head.baseIndivs = this.head.baseIndivs.filter(baseIndiv => baseIndiv.id != baseIndivId)
    // },

    // addBaseSpecial(){
    //   this.modalBaseSpecial = null;
    //   if (HelpStore.item){
    //     this.$bvModal.hide('modal-add-basespecial')

    //     if (HelpStore.item._index != null){
    //       this.head.baseSpecials[HelpStore.item._index] = HelpStore.item;
    //     }
    //     else {
    //       this.head.baseSpecials.push(HelpStore.item);
    //     }
    //     this.indexBaseSpecial++;
    //   }
    // },
    // editBaseSpecial(baseSpecial){
    //   this.modalBaseSpecial = baseSpecial;
    //   this.$bvModal.show('modal-add-basespecial');
    // },
    // deleteBaseSpecial(baseSpecialId){
    //   this.head.baseSpecials = this.head.baseSpecials.filter(baseSpecial => baseSpecial.id != baseSpecialId)
    // },

    // addIssuePhon(){
    //   this.modalIssuePhon = null;
    //   if (HelpStore.item){
    //     this.$bvModal.hide('modal-add-issuephon')

    //     if (HelpStore.item._index != null){
    //       this.head.issuePhons[HelpStore.item._index] = HelpStore.item;
    //     }
    //     else {
    //       this.head.issuePhons.push(HelpStore.item);
    //     }
    //     this.indexIssuePhon++;
    //   }
    // },
    // editIssuePhon(issuePhon){
    //   this.modalIssuePhon = issuePhon;
    //   this.$bvModal.show('modal-add-issuephon');
    // },
    // deleteIssuePhon(issuePhonId){
    //   this.head.issuePhons = this.head.issuePhons.filter(issuePhon => issuePhon.id != issuePhonId)
    // },

    // addIssueType(){
    //   this.modalIssueType = null;
    //   if (HelpStore.item){
    //     this.$bvModal.hide('modal-add-issuetype')

    //     if (HelpStore.item._index != null){
    //       this.head.issueTypes[HelpStore.item._index] = HelpStore.item;
    //     }
    //     else {
    //       this.head.issueTypes.push(HelpStore.item);
    //     }
    //     this.indexIssueType++;
    //   }
    // },
    // editIssueType(issueType){
    //   this.modalIssueType = issueType;
    //   this.$bvModal.show('modal-add-issuetype');
    // },
    // deleteIssueType(issueTypeId){
    //   this.head.issueTypes = this.head.issueTypes.filter(issueType => issueType.id != issueTypeId)
    // },

    // addIssueVar(){
    //   this.modalIssueVar = null;
    //   if (HelpStore.item){
    //     this.$bvModal.hide('modal-add-issuevar')

    //     if (HelpStore.item._index != null){
    //       this.head.issueVars[HelpStore.item._index] = HelpStore.item;
    //     }
    //     else {
    //       this.head.issueVars.push(HelpStore.item);
    //     }
    //     this.indexIssueVar++;
    //   }
    // },
    // editIssueVar(issueVar){
    //   this.modalIssueVar = issueVar;
    //   this.$bvModal.show('modal-add-issuevar');
    // },
    // deleteIssueVar(issueVarId){
    //   this.head.issueVars = this.head.issueVars.filter(issueVar => issueVar.id != issueVarId)
    // },

    // addPhonolEnv(){
    //   this.modalPhonolEnv = null;
    //   if (HelpStore.item){
    //     this.$bvModal.hide('modal-add-phonolenv')

    //     if (HelpStore.item._index != null){
    //       this.head.phonolEnvs[HelpStore.item._index] = HelpStore.item;
    //     }
    //     else {
    //       this.head.phonolEnvs.push(HelpStore.item);
    //     }
    //     this.indexPhonolEnv++;
    //   }
    // },
    // editPhonolEnv(phonolEnv){
    //   this.modalPhonolEnv = phonolEnv;
    //   this.$bvModal.show('modal-add-phonolenv');
    // },
    // deletePhonolEnv(phonolEnvId){
    //   this.head.phonolEnvs = this.head.phonolEnvs.filter(phonolEnv => phonolEnv.id != phonolEnvId)
    // },
  },
};
</script>
