<template>
  <div>
    <b-table striped small hover :items="hccDeesMapsData">
      <template #cell>
        <div class="text-left">
        test
        </div>
      </template>
      <template #cell(action)="data">
        <div class="text-right">
          <!-- <a
            href="#"
            v-if="editMode"
            @click="editRow($event, data.item.action.dees_map)"
            ><b-icon icon="pencil"></b-icon
          ></a> -->
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
    </b-table>
    <b-col sm="4" md="3" v-if="showTotalLable">
      Total {{ hccDeesMapsData.length }} {{ hccDeesMapsData.length === 1 ? 'entry' : "entries" }} in table
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  export default {
    name: 'HCCDeesMapsList',
    props: ['dees_map', 'editMode', 'showTotal'],
    data(){
      return {
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    computed: {
      hccDeesMapsData(){
        return this.dees_map.map((dees_map) => {
          return {
            dees_map: dees_map.dees_map,
            dees_group: dees_map.dees_group,
            dees_type: dees_map.dees_type.name,
            action: {
              dees_map: dees_map,
              id: dees_map.id
            }
          }
        })
      }
    },
    methods: {
      deleteRow(event, dees_map){
        event.preventDefault()
        // this.$store.dispatch('addNotification', {
        //   type: 'warning',
        //   text: `Authorhist deleted`
        // })
        this.$emit('delete', dees_map)
      },
      editRow(event, dees_map) {
        event.preventDefault();
        this.$emit("edit", dees_map);
      },
    }
  }
</script>
